import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPage } from '../features/page/PageSlice';
import AOS from "aos";
import photo from '../res/photo.jpg';
import chatdrop from '../res/chatdrop.png'

export default function Home() {
    const dispatch = useDispatch();

    const onAnimationEndHomeContainer = (e) => {
        e.target.style.opacity = '100%';
    }

    const changePage = (pageID) => dispatch(setPage({ on: pageID }));

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="home-container" onAnimationEnd={onAnimationEndHomeContainer}>
            <div className="home-info">
                <div className="intro-1">
                    <span className='m-text grey-text'>Hi</span>
                    <span className='l-text white-text'><span className='grey-text'>I'M</span> NITISH KUMAR</span>
                    <span className='s-text pink-text'>Full Stack developer & Software Engineer</span>
                </div>
                <div className="intro-2">
                    <div className="photo">
                        <img className='photo' src={photo} alt="profile_pic" />
                    </div>
                    <div className="container-light-blue">
                        <span className="m-s-text white-text">
                            I'm a Tech Enthusiast and Full Stack Developer, having 4 years of experience in programming Java, Python, Javascript.
                        </span>
                        <br />
                        <button className='btn contact-me-btn' onClick={(e) => changePage('contact')}>Contact Me</button>
                    </div>
                    <div className="container-blue" id='contact-me'>
                        <span className="m-text white-text bold">Connect with me -</span>
                        <div className="social-links">
                            <a href='https://www.linkedin.com/in/nitish-kumar-3604a0227/'>
                                <svg className='social' xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                                    <path id="linkedin" className='social-svg-path' data-name="Icon awesome-linkedin" d="M29.25,2.25H2.243A2.26,2.26,0,0,0,0,4.521V31.479A2.26,2.26,0,0,0,2.243,33.75H29.25a2.266,2.266,0,0,0,2.25-2.271V4.521A2.266,2.266,0,0,0,29.25,2.25Zm-19.73,27H4.852V14.217H9.527V29.25ZM7.186,12.164A2.707,2.707,0,1,1,9.893,9.457a2.708,2.708,0,0,1-2.707,2.707ZM27.021,29.25H22.352V21.938c0-1.744-.035-3.987-2.426-3.987-2.433,0-2.805,1.9-2.805,3.86V29.25H12.452V14.217h4.479V16.27h.063a4.917,4.917,0,0,1,4.423-2.426c4.725,0,5.6,3.115,5.6,7.165Z" transform="translate(0 -2.25)" fill="#fff" />
                                </svg>
                            </a>
                            <a href='https://www.facebook.com/profile.php?id=100028940198765'>
                                <svg className='social' xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                                    <path id="facebook" className='social-svg-path' data-name="Icon awesome-facebook-square" d="M28.125,2.25H3.375A3.375,3.375,0,0,0,0,5.625v24.75A3.375,3.375,0,0,0,3.375,33.75h9.65V23.041H8.6V18h4.43V14.158c0-4.37,2.6-6.784,6.586-6.784a26.836,26.836,0,0,1,3.9.34V12h-2.2a2.521,2.521,0,0,0-2.842,2.723V18h4.836l-.773,5.041H18.475V33.75h9.65A3.375,3.375,0,0,0,31.5,30.375V5.625A3.375,3.375,0,0,0,28.125,2.25Z" transform="translate(0 -2.25)" fill="#fff" />
                                </svg>
                            </a>
                            <a href='https://github.com/Nitish987/'>
                                <svg className='social' xmlns="http://www.w3.org/2000/svg" width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
                                    <path id="github" className='social-svg-path' data-name="Icon awesome-github-square" d="M28.125,2.25H3.375A3.376,3.376,0,0,0,0,5.625v24.75A3.376,3.376,0,0,0,3.375,33.75h24.75A3.376,3.376,0,0,0,31.5,30.375V5.625A3.376,3.376,0,0,0,28.125,2.25ZM19.5,29.229c-.591.105-.809-.26-.809-.562,0-.38.014-2.32.014-3.888a2.74,2.74,0,0,0-.795-2.159c2.6-.288,5.344-.647,5.344-5.14a3.61,3.61,0,0,0-1.2-2.742,4.4,4.4,0,0,0-.12-3.164c-.977-.3-3.213,1.259-3.213,1.259a11.061,11.061,0,0,0-5.85,0s-2.236-1.561-3.213-1.259a4.364,4.364,0,0,0-.12,3.164,3.531,3.531,0,0,0-1.1,2.742c0,4.472,2.623,4.852,5.224,5.14a2.548,2.548,0,0,0-.745,1.568,2.467,2.467,0,0,1-3.4-.977,2.45,2.45,0,0,0-1.793-1.2c-1.139-.014-.077.717-.077.717.759.352,1.294,1.7,1.294,1.7.682,2.088,3.945,1.385,3.945,1.385,0,.977.014,2.566.014,2.855s-.211.668-.809.563A11.665,11.665,0,0,1,4.2,18.1,11.158,11.158,0,0,1,15.6,6.743,11.408,11.408,0,0,1,27.281,18.1,11.558,11.558,0,0,1,19.5,29.229Zm-6.9-4.3c-.134.028-.26-.028-.274-.12s.077-.2.211-.225.26.042.274.134S12.741,24.9,12.6,24.933Zm-.668-.063c0,.091-.105.169-.246.169s-.26-.063-.26-.169.105-.169.246-.169S11.932,24.764,11.932,24.87Zm-.963-.077c-.028.091-.169.134-.288.091s-.225-.134-.2-.225.169-.134.288-.105C10.912,24.6,11,24.7,10.969,24.792Zm-.865-.38c-.063.077-.2.063-.3-.042s-.134-.225-.063-.288.2-.063.3.042S10.167,24.356,10.1,24.412Zm-.64-.64c-.063.042-.183,0-.26-.105s-.077-.225,0-.274.2-.014.26.091a.206.206,0,0,1,0,.288Zm-.457-.682c-.063.063-.169.028-.246-.042-.077-.091-.091-.2-.028-.246s.169-.028.246.042C9.056,22.936,9.07,23.041,9.007,23.091Zm-.471-.52a.132.132,0,0,1-.2.028c-.091-.042-.134-.12-.105-.183a.165.165,0,0,1,.2-.028C8.522,22.437,8.564,22.514,8.536,22.57Z" transform="translate(0 -2.25)" fill="#fff" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="intro-3">
                    <div className="container-blue" data-aos="fade-up">
                        <span className="s-text white-text">
                            I Nitish Kumar currently pursuing B. Tech in Computer Science and
                            Engineering from Dr. A.P.J. Abdul Kalam Technical University.
                            I chose this field because i am enthusiastic about advanced
                            technology.
                        </span>
                        <br />
                        <button className='btn about-me-btn' onClick={(e) => changePage('about')}>About Me</button>
                    </div>
                    <div className="container-light-blue" data-aos="fade-up">
                        <span className="m-text white-text bold">Latest Project -</span>
                        <div className="latest-project-card">
                            <img src={chatdrop} alt="chatdrop" />
                            <span className='s-text white-text'>
                                <a href="https://opensource.chatdrop.in/" style={{color: 'white'}}>Chatdrop</a> is Ai powered social media platfrom developed by technitish and Chatdrop Team.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
