import React, { useEffect } from 'react';
import AOS from "aos";
import photo from '../res/photo.jpg';
import java from '../res/java.png';
import java1 from '../res/java1.png';
import html from '../res/html.jpg';
import js from '../res/js.jpg';
import python from '../res/python.jpg';
import python1 from '../res/python.png';
import pushpak from '../res/pushpak.jpg';
import abes from '../res/abes.jpg';
import abesit from '../res/abesit.png';
import dataScience from '../res/data_science.png';
import technicalPaper from '../res/technical_paper.jpg';
import bingogames from '../res/bingo_games.png';
import chatdrop from '../res/chatdrop.png';
import chatbook from '../res/chatbook.png';
import finder from '../res/finder.png';
import finsal from '../res/finsal.png';
import tenicons from '../res/tenicons.png';

export default function About() {
    const onAnimationEndAboutContainer = (e) => {
        e.target.style.opacity = '100%';
    }

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="about-container" onAnimationEnd={onAnimationEndAboutContainer}>
            <div className="about-info">
                <div className="info">
                    <h1 className='l-text pink-text'>About</h1>
                    <div className="info-photo-text container-blue">
                        <div className="info-photo">
                            <img src={photo} alt="profile_pic" />
                        </div>
                        <div className="info-text">
                            <p className='m-s-text white-text'><span className="grey-text">I'm</span> Nitish Kumar</p>
                            <p className='m-s-text white-text'><span className="grey-text">My Email is</span> contact.dev.nitish@gmail.com</p>
                            <p className='m-s-text white-text'><span className="grey-text">I'm</span> Pursuing B.Tech</p>
                            <p className='m-s-text white-text'><span className="grey-text">Available for</span> Freelancing</p>
                        </div>
                    </div>
                </div>
                <div className="facts">
                    <h1 className='l-text pink-text' data-aos="fade-up">Facts</h1>
                    <p className='white-text' data-aos="fade-up">About my achievements, I am certified in Computer Programming Languages and worked on Technical Research Paper which is published in the book "Emerging Trends and Technologies in Computer Science & Engineering" of the College Raj Kumar Goel Institute of Technology.</p>
                    <h4 className="s-text grey-text" data-aos="fade-up">Certificates</h4>
                    <div className="certificates" data-aos="fade-up">
                        <img src={java} alt="java" />
                        <img src={java1} alt="java" />
                        <img src={html} alt="html" />
                        <img src={js} alt="javascript" />
                        <img src={python} alt="python" />
                        <img src={python1} alt="python" />
                        <img src={abes} alt="hackathon" />
                        <img src={abesit} alt="hackathon" />
                        <img src={dataScience} alt="data_science" />
                        <img src={technicalPaper} alt="technical_paper" />
                        <img src={pushpak} alt="drone" />
                    </div>
                    <h4 className="s-text grey-text" data-aos="fade-up">Projects</h4>
                    <p className="white-text" data-aos="fade-up">I have worked on <b>25+ projects</b> some are on <a href='https://github.com/Nitish987/' className='link link-pink'><b>Github</b></a> and some apps are available on Google Play Store and some are hosted. My top 6 Projects are listed here -</p>
                    <div className="projects" data-aos="fade-up">
                        <div className="project-card">
                            <img src={chatdrop} alt="chatdrop" />
                            <p className='white-text'><a href='https://opensource.chatdrop.in' className='link link-pink'><b>Chatdrop</b></a> is Ai Powered - Social Media Application where people can connect, share and explore.</p>
                        </div>
                        <div className="project-card">
                            <img src={bingogames} alt="bingo_games" />
                            <p className='white-text'><b>Bingo Games</b> is a Android Games Application where players can play online with friends world wide.</p>
                        </div>
                        <div className="project-card">
                            <img src={tenicons} alt="tenicons" />
                            <p className='white-text'><a href="https://github.com/Nitish987/tenicons-website" className='link link-pink'><b>Tenicons</b></a> is a Image sharing Website in which user can upload their photographs and distrubute them world wide to other users.</p>
                        </div>
                        <div className="project-card">
                            <img src={chatbook} alt="chatbook" />
                            <p className='white-text'><b>Chatbook</b> is a Android Social Media and Chatting Application where users can connect and make friends world wide.</p>
                        </div>
                        <div className="project-card">
                            <img src={finder} alt="finder" />
                            <p className='white-text'><b>Finder</b> is a Desktop Software used for creating salaries and challans, which makes it very easy to build salary sheets.</p>
                        </div>
                        <div className="project-card">
                            <img src={finsal} alt="finsal" />
                            <p className='white-text'><b>Finsal</b> Finsal is a web app which is used to create salary and challans sheets and store all sheets on cloud.</p>
                        </div>
                    </div>
                </div>
                <div className="skills">
                    <h1 className="l-text pink-text" data-aos="fade-up">Skills</h1>
                    <div className="skills-card">
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Front-End [ HTML, CSS, JavaScript ] - 90%</h4>
                            <div className="progress" id='frontend'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Front-End Frameworks [ React, Bootstarp ] - 95%</h4>
                            <div className="progress" id='frontendframeworks'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Back-End [ Python, Nodejs ] - 94%</h4>
                            <div className="progress" id='backend'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Back-End Frameworks [ Django, Expressjs ] - 92%</h4>
                            <div className="progress" id='backendframeworks'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>UI/UX Tool [ Adobe XD ] - 80%</h4>
                            <div className="progress" id='uiux'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Data Structure & Algorithms [ Java ] - 80%</h4>
                            <div className="progress" id='dsa'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Android App Development [ Java ] - 85%</h4>
                            <div className="progress" id='appdev'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Database [ MySQL, MongoDB, Firestore ] - 80%</h4>
                            <div className="progress" id='database'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Cloud [Google Cloud, Firebase] - 70%</h4>
                            <div className="progress" id='cloud'></div>
                        </div>
                        <div className="myskill" data-aos="fade-up">
                            <h4 className='s-text pink-text'>Git & GitHub - 95%</h4>
                            <div className="progress" id='git'></div>
                        </div>
                    </div>
                </div>
                <div className="education">
                    <h1 className="l-text pink-text" data-aos="fade-up">Education</h1>
                    <p className='white-text' data-aos="fade-up">About my education, I have completed schooling from St. Teresa's Academy. Currently pursuing B.Tech in Computer Science & Engineering (IOT) from Dr. A.P.J. Abdul Kalam Technical University.</p>
                    <div className="school container-blue" data-aos="fade-up">
                        <h4 className='s-text pink-text'>St. Teresa's Academy</h4>
                        <p className='text white-text'>10th <span className="grey-text">- [2018]</span></p>
                        <p className='text white-text'>12th <span className="grey-text">- [2020]</span></p>
                    </div>
                    <div className="college container-blue" data-aos="fade-up">
                        <h4 className='s-text pink-text'>Dr. A.P.J. Abdul Kalam Technical University</h4>
                        <p className='text white-text'>B.Tech <span className="grey-text">Computer Science & Engineering (Internet of Things)</span> - Currently Pursuing - [2020 - 2024]</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
