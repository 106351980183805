import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from '../features/page/PageSlice';
import home from '../res/home.png';
import about from '../res/about.png';
import contact from '../res/contact.png';
// import blog from '../res/blog.png';

export default function Navbar() {
    const page = useSelector((state) => state.page.on);
    const dispatch = useDispatch();

    const onAnimationEndNavContainer = (e) => {
        e.target.style.opacity = '100%';
    }

    const changePage = (pageID) => dispatch(setPage({ on: pageID }));

    return (
        <div className="nav-container" onAnimationEnd={onAnimationEndNavContainer}>
            <div className="navigation">
                <div className={`nav-items ${page === 'home' ? 'active-item' : ''}`} onClick={(e) => changePage('home')}>
                    <img src={home} alt="home" />
                </div>
                <div className={`nav-items ${page === 'about' ? 'active-item' : ''}`} onClick={(e) => changePage('about')}>
                    <img src={about} alt="about" />
                </div>
                <div className={`nav-items ${page === 'contact' ? 'active-item' : ''}`} onClick={(e) => changePage('contact')}>
                    <img src={contact} alt="contact" />
                </div>
                {/* <div className={`nav-items ${page === 'blog' ? 'active-item' : ''}`} onClick={(e) => changePage('blog')}>
                    <img src={blog} alt="blog" />
                </div> */}
                <div className="divider"></div>
                <div className="nav-selected-item ">
                    <span className='nav-select-text'>{page}</span>
                </div>
            </div>
            <div className="copyright">
                <span>Copyright &copy; 2023 | Nitish Kumar</span>
            </div>
        </div>
    )
}
