import './App.css';
import "aos/dist/aos.css";
import { useSelector } from 'react-redux';
import About from './components/About';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
// import Blog from './components/Blog';

function App() {
    const page = useSelector((state) => state.page.on);

    return (
        <>
            <Navbar/>
            {page === 'home' && <Home/>}
            {page === 'about' && <About/>}
            {page === 'contact' && <Contact/>}
            {/* {page === 'blog' && <Blog/>} */}
        </>
    );
}

export default App;
