import { createSlice } from '@reduxjs/toolkit';

export const pageSlice = createSlice({
  name: 'page',
  initialState: {
    on: 'home',
  },
  reducers: {
    setPage: (state, action) => {
      state.on = action.payload.on;
    },
  },
})

export const { setPage } = pageSlice.actions

export default pageSlice.reducer